<template>
  <div class="SportsManagement">
    <el-card class="box-card">
      <div class="main-card">
        <el-table :data="tableData" style="width: 100%" height="550">
          <el-table-column label="赛事名称" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="比赛日期" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.Cdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报名截止" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.RDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别数目" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.PDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button
              >
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >报名</el-button
              >
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >购买门票</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      options: [
        {
          value: "选项1",
          label: "所有",
        },
        {
          value: "选项2",
          label: "可见",
        },
        {
          value: "选项3",
          label: "不可见",
        },
      ],
      value: "所有",
      value2: true,
      tableData: [
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 1,
          name: "赛事测试",
          flag: true,
        },
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 2,
          name: "赛事测试2",
          flag: false,
        },
      ],
      input1: "",
      input2: "",
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      dialogFormVisible: false,
      dialogVisible: false,
      form: {
        name1: "",
        name2: "杭州",
        city: "杭州",
        address: "杭州",
        region1: "",
        region2: "",
        region3: "",
        date1: "2016-05-02",
        date2: "2016-05-04",
        date3: "2016-04-02",
        date4: "2016-04-04",
        dateTime1: new Date(2000, 10, 10, 10, 10),
        dateTime2: new Date(2000, 10, 10, 10, 10),
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        url: "wwww.baidu.com",
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleDetail(index, row) {
      console.log(index, row);
      this.$router.push({ name: "CompanyTabs", params: { back: row.name } });
    },
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.form.name1 = row.name;
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    showDialog() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less">
.SportsManagement {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
      /* switch按钮样式 */
      .switch .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
        top: 0;
        left: 10px;
      }
      /*打开时文字位置设置*/
      .switch .el-switch__label--right {
        z-index: 1;
      }
      /* 调整打开时文字的显示位子 */
      .switch .el-switch__label--right span {
        margin-right: 9px;
      }
      /*关闭时文字位置设置*/
      .switch .el-switch__label--left {
        z-index: 1;
      }
      /* 调整关闭时文字的显示位子 */
      .switch .el-switch__label--left span {
        margin-left: 13px;
      }
      /*显示文字*/
      .switch .el-switch__label.is-active {
        display: block;
      }
      /* 调整按钮的宽度 */
      .switch.el-switch .el-switch__core,
      .el-switch .el-switch__label {
        width: 50px !important;
        margin: 0;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
