<template>
  <div class="SportsManagement">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <span style="height: 40px; line-height: 40px">姓名：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="input1"
          ></el-input>
          <span style="height: 40px; line-height: 40px">证件号码：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="input1"
          ></el-input>
          <el-button style="height: 40px" type="primary" plain>搜索</el-button>
        </div>
      </div>

      <div class="main-card">
        <el-table :data="tableData" style="width: 100%" height="550">
          <el-table-column label="姓名" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.Cdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="生日" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.RDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="身份证号" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.PDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="手机号" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="修改选手" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="200px">
        <div>
          <el-form-item label="国籍:">
            <el-select v-model="form.region2" placeholder="请选择国籍">
              <el-option label="中国" value="shanghai"></el-option>
              <el-option label="小日本" value="beijing"></el-option>
              <el-option label="狗美国" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件类型:">
            <el-select v-model="form.region2" placeholder="请选择">
              <el-option label="身份证" value="1"></el-option>
              <el-option label="护照" value="2"></el-option>
              <el-option label="港澳通行证" value="3"></el-option>
              <el-option label="台胞证" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码">
            <el-input v-model="form.name1"></el-input>
          </el-form-item>
          <el-form-item label="证件图片:">
            <el-input v-model="form.name1"></el-input>
          </el-form-item>
          <el-form-item label="姓名(和证件保持一致):">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="性别:">
            <el-radio v-model="radio" label="1">男</el-radio>
            <el-radio v-model="radio" label="2">女</el-radio>
          </el-form-item>
          <el-form-item label="专业情况:">
            <el-radio v-model="radioTwo" label="1">业余</el-radio>
            <el-radio v-model="radioTwo" label="2">专业</el-radio>
          </el-form-item>
          <el-form-item label="参赛性质:">
            <el-radio v-model="radioThree" label="1">个人</el-radio>
            <el-radio v-model="radioThree" label="2">单位</el-radio>
          </el-form-item>
          <el-form-item label="参赛单位:" v-if="this.radioThree == 2">
            <el-select v-model="form.region2" placeholder="请选择单位">
              <el-option label="杭州" value="shanghai"></el-option>
              <el-option label="七台河" value="beijing"></el-option>
              <el-option label="大连" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="领队姓名:">
            <el-input v-model="form.name2"></el-input>
          </el-form-item>
          <el-form-item label="领队电话:">
            <el-input v-model="form.name2"></el-input>
          </el-form-item>
          <el-form-item label="详细地址:">
            <el-input v-model="form.name2"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDialog">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [
        {
          name: "选手",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      radio: "1",
      radioTwo: "1",
      radioThree: "1",
      options: [
        {
          value: "选项1",
          label: "所有",
        },
        {
          value: "选项2",
          label: "可见",
        },
        {
          value: "选项3",
          label: "不可见",
        },
      ],
      value: "所有",
      value2: true,
      tableData: [
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 1,
          name: "赛事测试",
          flag: true,
        },
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 2,
          name: "赛事测试2",
          flag: false,
        },
      ],
      input1: "",
      input2: "",
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      dialogFormVisible: false,
      dialogVisible: false,
      form: {
        name1: "",
        name2: "杭州",
        city: "杭州",
        address: "杭州",
        region1: "",
        region2: "",
        region3: "",
        date1: "2016-05-02",
        date2: "2016-05-04",
        date3: "2016-04-02",
        date4: "2016-04-04",
        dateTime1: new Date(2000, 10, 10, 10, 10),
        dateTime2: new Date(2000, 10, 10, 10, 10),
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        url: "wwww.baidu.com",
      },
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleDetail(index, row) {
      console.log(index, row);
      this.$router.push({ name: "CompanyTabs", params: { back: row.name } });
    },
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.form.name1 = row.name;
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    showDialog() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less">
.SportsManagement {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
      /* switch按钮样式 */
      .switch .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
        top: 0;
        left: 10px;
      }
      /*打开时文字位置设置*/
      .switch .el-switch__label--right {
        z-index: 1;
      }
      /* 调整打开时文字的显示位子 */
      .switch .el-switch__label--right span {
        margin-right: 9px;
      }
      /*关闭时文字位置设置*/
      .switch .el-switch__label--left {
        z-index: 1;
      }
      /* 调整关闭时文字的显示位子 */
      .switch .el-switch__label--left span {
        margin-left: 13px;
      }
      /*显示文字*/
      .switch .el-switch__label.is-active {
        display: block;
      }
      /* 调整按钮的宽度 */
      .switch.el-switch .el-switch__core,
      .el-switch .el-switch__label {
        width: 50px !important;
        margin: 0;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
