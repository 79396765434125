<template>
  <div class="CompanyTabs">
    <el-page-header @back="goBack" :content="nav" title=""> </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="1">
        <Info1></Info1>
      </el-tab-pane>
      <el-tab-pane label="参赛信息" name="2">
        <Info2></Info2>
      </el-tab-pane>
      <el-tab-pane label="选手信息" name="3">
        <Info3></Info3>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Info1 from "../../components/companyInfo.vue";
import Info2 from "../../components/contestInfo.vue";
import Info3 from "../../components/competitorInfo.vue";
export default {
  data() {
    return {
      nav: this.$route.params.back,
      activeName: "1",
    };
  },
  components: {
    Info1,
    Info2,
    Info3,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.CompanyTabs {
  .navigationBar {
    display: flex;
  }
}
</style>
