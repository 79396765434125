<template>
  <div class="information">
    <el-card class="box-card">
      <div v-for="item in list" :key="item" class="text item">
        {{ item }}
        <span class="info">{{ 123 }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        "单位名称：",
        "单位负责人：",
        "单位负责人电话：",
        "单位邮箱：",
        "国籍：",
        "单位电话：",
        "所在省份：",
        "所在城市：",
        "单位详细地址：",
        "银行开户名：",
        "开户银行：",
        "银行账号：",
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.information {
  position: relative;
  .info {
    position: absolute;
    left: 200px;
  }
  .text {
    font-size: 14px;
  }

  .item {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
}
</style>
